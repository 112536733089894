import Model from '../Model';
import {
    actions
} from '@/store';
import router from '@/router';

class ApiRequest extends Model {
    // afterRequest(response) {
        
    //     if (response.status === 401) {
    //         actions.resetAuth();
    //         return router.push({
    //             name: 'Login'
    //         }).catch(() => ({}));
    //     }
    //     return response.data;
    // }
}

export default new ApiRequest();